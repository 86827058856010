import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "Managed Microsoft 365",
  sectionHeader:
    "The tools you need to maximise productivity, without the hassle.",
  sectionContent:
    "The Microsoft 365 platform gives you all the industry-standard tools you need to work smarter and faster. goSystem provides fully managed Microsoft 365 solutions at the lowest price in the industry. From Microsoft Office licences to cloud Exchange Server, goSystem's managed Microsoft 365 solutions take the hassle out of 365, allowing you to get on with what is vital to your business.",
  splitTag: "Industry-Standard Tools",
  splitHeading: "Get everything you need, without the management.",
  splitContent:
    "Get the industry-standard tools you know and love, without the hassle of user and service management. goSystem provide the tools your employees need to maximise productivity without the management overhead. Far gone are the days of on-site Exchange servers and expensive office license subscriptions. With 24/7 support, bulk user provisioning and migration services, goSystem take the hassle out of 365.",
  splitImg: "Microsoft",
  callToAction: "Get the industry-standard tools you need with goSystem.",
};

export default () => ServicesTemplate(config);
